import Container from "react-bootstrap/Container";
import {Form} from "react-router-dom";
import Button from "react-bootstrap/Button";


export const ModifyAppointment = () => {

    return (
        <Container className={"d-flex bg-light flex-wrap justify-content-start rounded-3 p-3"}>
            <h2 className={"w-100 flex-shrink-0 "}>Modify Appointment</h2>
            <Form method={"get"} action={"reschedule"}>
                <Button type={"submit"}>Reschedule</Button>
            </Form>
        </Container>

    );
}