import Container from "react-bootstrap/Container";
import {Form, json, NavLink, Outlet, redirect, useLoaderData} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {Col, Row} from "react-bootstrap";

export const flashAdminLoader = (getAuth) => async () => {
    try {
        let auth = await getAuth();
        let artist = auth.username === "jockerse" ? "erika" : auth.username;
        let response = await fetch(`https://botanical.ink/api/admin/flash/sets?artist=${artist}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.idToken.getJwtToken()}`,
            },
        });
        if (response.ok) {
            let sets = await response.json();
            return json({
                sets: sets,
                username: artist,
            })
        } else {
            return json({
                error: response.status
            })
        }
    } catch (error) {
        console.log(error)
        if (error === "NOT VALID") {
            return redirect("/login")
        } else {
            throw error
        }
    }
}

export const createFlashSetAction = (getAuth) => async ({params, request}) => {
    console.log("create flash set action")
    try {
        let auth = await getAuth();
        let artist = auth.username === "jockerse" ? "erika" : auth.username;
        let data = {
            artist: artist
        }
        let response = await fetch("https://botanical.ink/api/admin/flash/sets", {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.idToken.getJwtToken()}`,
            }
        });
        if (response.ok) {
            let responseData = await response.json();
            return redirect(`edit/${responseData.id}`);
        } else {
            return response;
        }
    } catch (error) {
        console.log(error)
        if (error === "NOT VALID") {
            return redirect("/login")
        } else {
            throw error
        }
    }
}

export const FlashAdmin = () => {
    const loaderData = useLoaderData();
    return (
        <>
            <Container className="text-light d-flex flex-column rounded-3 mb-3">
                <h3 className={"mx-auto"}>Flash</h3>
                <span className={"mx-auto"}>Click on a row to edit flash set</span>
            </Container>

            <Container className={"d-grid row-gap-1 column-gap-1 bg-white rounded mb-3"}>
                <Row className={"bg-light fw-bold rounded-top border-bottom"}>
                    <Col>Set Name</Col>
                    <Col xs={4} md={2}>Start Date</Col>
                    <Col xs={4} md={2}>End Date</Col>
                </Row>
                {loaderData.sets && loaderData.sets.length > 0 &&
                    loaderData.sets.map((set, idx) => (
                        <Row key={set.id} className={"border-bottom"} as={NavLink} to={`edit/${set.id}`}>
                            <Col>{idx + 1}. {set.name ? set.name : "[NEW]"}</Col>
                            <Col xs={4} md={2}>{set.startDate}</Col>
                            <Col xs={4} md={2}>{set.endDate}</Col>
                        </Row>
                    ))
                }
                <Row>
                    <Col></Col>
                    <Col xs={8} className={"d-flex"}>
                        <Form className={"m-auto p-2"} method={"post"}>
                            <Button className={""} type={"submit"}>Create New Set</Button>
                        </Form>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            <Outlet context={loaderData}/>
        </>


    );
}