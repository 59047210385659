import Container from "react-bootstrap/Container";
import {useEffect, useState} from "react";
import {doJsonGET} from "../../../util/FetchUtils";
import React, {
    Alert,
    Col,
    FormControl,
    FormGroup,
    FormLabel,
    FormSelect, FormText,
    Row,
} from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import {ReactComponent as SaleIcon} from "../../../../assets/images/icons/plant_pot_green.svg"
import {Controller, useFormContext} from "react-hook-form";
import RequiredText from "../../formparts/RequiredText";
import LeafSpinner from "../../../util/LeafSpinner";


export const FlashTattoo = ({fullId, getError, flashSets = []}) => {
    const {
        register,
        control,
    } = useFormContext();

    const [loading, setLoading] = useState(true)
    const [selectedSet, setSelectedSet] = useState(getFirstSet(flashSets));
    const [pieceData, setPieceData] = useState(null);
    const [selectedPiece, setSelectedPiece] = useState(null);

    useEffect(() => {
        if (selectedSet) {
            loadFlashPieces(selectedSet).then(data => {
                setPieceData(data);
                if (!data.error && data.data && data.data.length > 0) {
                    setSelectedPiece(data.data[0].piece);
                }
            })
            setLoading(false);
        }
    }, [selectedSet]);

    useEffect(() => {
        setSelectedSet(getFirstSet(flashSets));
    }, [flashSets]);

    const handleSetChange = (newSet) => {
        setLoading(true);
        setSelectedSet(newSet);
    }

    return (
        <>
            <FormGroup className={"w-75 mx-auto mb-3"}>
                <FormSelect
                    aria-label={"Flash set select"}
                    onChange={e => handleSetChange(e.target.value)}
                >
                    {flashSets && flashSets.map(set => (
                        <option key={set.id} value={set}>{set.name}</option>
                    ))}
                </FormSelect>
            </FormGroup>
            {loading && <LeafSpinner/>}
            {(!loading && pieceData && !pieceData.error) &&
                <Container>
                    <Row xs={1} lg={2}>
                        <Col className={"mb-3 row-gap-3"}>
                            <Controller
                                name={`${fullId}.flashId`}
                                control={control}
                                defaultValue={pieceData.data[0].piece.id}
                                render={({field}) => (
                                    <FlashPiecesGallery
                                        pieceData={pieceData.data}
                                        onPieceSelected={(piece) => {
                                            field.onChange(piece.id);
                                            setSelectedPiece(piece);
                                        }}
                                    />
                                )}
                            />
                        </Col>
                        <Col className={""}>
                            <FlashPromo set={selectedSet}/>
                            <h5>Selected: {selectedPiece.name}</h5>
                            <FormGroup className="mb-3" controlId="description">
                                <FormLabel>Description <RequiredText
                                    isMissing={!!getError("description")}/></FormLabel>
                                <FormControl as="textarea" rows={3}
                                    placeholder={selectedSet.setDescription}
                                             {...register(`${fullId}.description`, {
                                                 required: true
                                             })}
                                             isInvalid={!!getError("description")}
                                />
                                <FormText className={"text-light"}>Use the "Add Another Tattoo" button below to book more than one</FormText>
                            </FormGroup>
                            <FormGroup className="mb-3" controlId="placement">
                                <FormLabel>Tattoo Placement <RequiredText
                                    isMissing={!!getError("placement")}/></FormLabel>
                                <FormControl as="textarea" rows={3}
                                             placeholder="Describe the placement you're looking for"
                                             {...register(`${fullId}.placement`, {
                                                 required: true
                                             })}
                                             isInvalid={!!getError("placement")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    );
}

export const FlashPromo = ({set}) => {
    if (!set.multiTattooDeal) {
        return (
            <></>
        );
    }

    return (
        <Alert variant={"warning"} className={"d-flex align-items-center mb-3 text-black fw-bold"}>
            <SaleIcon className="fs-2 me-2" style={{height: "1em", width: "1em", minWidth: "1em"}}/>
            <span className={"text-center flex-grow-1"}>{set.dealText}</span>
            <SaleIcon className="fs-2 ms-2" style={{height: "1em", width: "1em", minWidth: "1em"}}/>
        </Alert>
    );
}

export const FlashPiecesGallery = ({pieceData, onPieceSelected}) => {
    const handleSlide = (index) => {
        onPieceSelected(pieceData[index].piece)
    }
    return (
        <ImageGallery
            items={pieceData}
            showPlayButton={false}
            useWindowKeyDown={false}
            onSlide={handleSlide}
        />
    );

}

export const getFirstSet = (sets) => {
    if (sets.length > 0) {
        return sets[0];
    }
}

export const loadFlashPieces = async (flashSet) => {
    let setId = flashSet.id;
    const response = await doJsonGET(`book-now/flash/${setId}`);
    if (response.ok) {
        let pieces = await response.json();

        let processedPieces = pieces
            .sort((a, b) => {
                return a.piece.displayOrder - b.piece.displayOrder;
            })
            .map((data, index) => (
                {
                    piece: data.piece,
                    original: data.imageUrl,
                    thumbnail: data.imageUrl,
                    originalTitle: data.piece.name,
                    thumbnailTitle: data.piece.name,
                }
            ));
        return {
            data: processedPieces,
            error: null,
        }
    } else {
        return {
            data: null,
            error: "error",
        };
    }
}