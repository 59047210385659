import AppointmentScheduleV2 from "../../forms/createV2/AppointmentScheduleV2";
import Button from "react-bootstrap/Button";
import {Form} from "react-router-dom";
import React from "react";


export const Reschedule = () => {

    return (
        <>
            <AppointmentScheduleV2/>
            <Form method={"get"} action={".."} className={"d-flex justify-content-center"}>
                <Button type={"submit"} className={"w-50 mx-auto"}
                        style={{backgroundColor: "grey"}}>Cancel</Button>
            </Form>

        </>
    );

}